<template>
  <div class="container">
    <div class="container mb-2">    
    <!-- <v-divider class="mx-4" color="black"></v-divider> -->
    <h1 class="text-center display-3 font-weight-thin mt-2">Main Dishes</h1>
    <!-- <v-divider class="mx-4" color="black"></v-divider> -->
        <div class="container text-center">
      <v-divider class="mx-4 mt-4 mb-4" color="black"></v-divider>
      <p class="font-italic">
        <b>Täglich von 11:00 - 16:00 Uhr</b> <br />daily from 11 am - 4 pm
      </p>
      <v-divider class="mx-4" color="black"></v-divider>
    </div>
    </div>
    <!-- <backtospeisekarte></backtospeisekarte> -->
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          color="green"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="breakfast"
        :search="search"
        :items-per-page="-1"
      >
        <template v-slot:item.picture="{ item }">
          <v-img
            :src="require(`../assets/images/${item.picture}`)"
            max-width="150"
          ></v-img>
        </template>
        <template v-slot:item.speise="{ item }">
          <b>{{ item.speise }}</b>
        </template>
        <template v-slot:item.preis="{ item }">
          <b>{{ item.preis }}</b>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// import Backtospeisekarte from './Backtospeisekarte.vue';
export default {
  // components: { Backtospeisekarte },
  name: 'Hauptspeise',
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Speise',
          value: 'speise',
        },
        { text: 'Picture', value: 'picture' },
        { text: 'Allergene', value: 'allergene' },
        { text: 'Inhalt', value: 'inhalt' },
        { text: 'Preis', value: 'preis' },
      ],
      breakfast: [
        {
          id: 1,
          speise: 'GULASCH | goulash (beef)',
          picture: 'gulasch.png',
          allergene: '(A, G, O, P)',
          inhalt: 'mit Semmelknödel | with homemade dumplings',
          preis: '14,90 €',
        },
        {
          id: 2,
          speise: 'KLEINES GULASCH | small goulash (beef)',
          picture: 'platzhalter.png',
          allergene: '(A, G, O, P)',
          inhalt: 'mit Semmel | with a roll',
          preis: '11,20 €',
        },
        {
          id: 3,
          speise: 'GERÖSTETE KNÖDEL MIT EI | roasted dumplings with egg',
          picture: 'eiernockel.png',
          allergene: '(A, C, G, M)',
          inhalt: 'mit Ei, grüner Salat | beside green salad',
          preis: '12,40 €',
        },
        {
          id: 4,
          speise:
            'GEDÜNSTERTER ZWIEBELROSTBRATEN | steamed roast beef in onion sacue',
          picture: 'zwiebelrostbraten.png',
          allergene: '(A, M, O, P)',
          inhalt:
            'mit Bratäpfeln, Röstzwiebeln und Essigurkerl | with fried potatoes, fried onions & pickles',
          preis: '25,80 €',
        },
        {
          id: 5,
          speise:
            'SCHWEINS-/HÜHNERSCHNITZEL | pork/chicken schnitzel with potato salad',
          picture: 'schweinsschnitzel.png',
          allergene: '(A, C, M)',
          inhalt: 'mit Erdäpfelsalat | with potato salad',
          preis: '15,90 € / 16,50 €',
        },
        {
          id: 6,
          speise:
            'ORIGINAL WIENER SCHNITZEL VOM KALB | original viennese schnitzel from veal',
          picture: 'kalbsschnitzel.png',
          allergene: '(A, C, G)',
          inhalt: 'mit Petersilerdäpfel | with parsley-butter potatoes',
          preis: '25,90 €',
        },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
