<template>
  <div class="container">
    <div class="container mb-2">
      <!-- <v-divider class="mx-4" color="black"></v-divider> -->
      <h1 class="text-center display-3 font-weight-thin mt-2">Soups</h1>
      <!-- <v-divider class="mx-4" color="black"></v-divider> -->
    </div>
      <div class="container text-center mb-2">
      <v-divider class="mx-4 mb-4" color="black"></v-divider>
      <p class="font-italic">
        <b>Täglich von 11:00 - 16:00 Uhr</b> <br />daily from 11 am - 4 pm
      </p>
      <v-divider class="mx-4 mb-4" color="black"></v-divider>
    </div>
    <!-- <backtospeisekarte></backtospeisekarte> -->
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="suppe"
        :search="search"
        :items-per-page="-1"
      >
        <!-- <template v-slot:item.picture="{ item }">
          <v-img
            :src="require(`../assets/images/${item.picture}`)"
            max-width="150"
          ></v-img>
        </template> -->
        <template v-slot:item.speise="{ item }">
          <b>{{ item.speise }}</b>
        </template>
        <template v-slot:item.preis="{ item }">
          <b>{{ item.preis }}</b>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// import Backtospeisekarte from './Backtospeisekarte.vue';
export default {
  // components: { Backtospeisekarte },
  name: 'Suppe',
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Suppe',
          value: 'speise',
        },
        { text: 'Allergene', value: 'allergene' },
        { text: 'Inhalt', value: 'inhalt' },
        { text: 'Preis', value: 'preis' },
      ],
      suppe: [
        {
          id: 1,
          speise: 'RINDSUPPE | BEEF BROTH',
          allergene: '(A, C, G, L)',
          inhalt:
            'mit Frittaten, Grießnockerl & Leberknödel | with sliced pancakes, semolina- or liver dumplings',
          preis: '5,60 €',
        },
        {
          id: 2,
          speise: 'Gulaschsuppe | goulash soup',
          allergene: '(A, G, O, P)',
          inhalt: 'mit Semmel | with a roll',
          preis: '8,30 €',
        },
        // {
        //   id: 3,
        //   speise: 'Tagessuppe (MO-FR) groß | soup of the day large',
        //   allergene: '(A, G, O, P)',
        //   inhalt: '',
        //   preis: '4,10 €',
        // },
        // {
        //   id: 4,
        //   speise: 'Tagessuppe (MO-FR) klein | soup of the day small',
        //   allergene: '(A, G, O, P)',
        //   inhalt: '',
        //   preis: '3,10 €',
        // },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
