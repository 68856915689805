<template>
  <div class="container">
    <h1 class="text-center display-3 font-weight-thin mt-8">Kaffee | Coffee</h1>
    <v-divider class="mx-4 mt-8" color="black"></v-divider>
    <div class="container text-center">
      <p class="font-italic">
        <b>Alle Kaffeevariationen auch koffeinfrei erhältlich</b> <br />Each
        coffee is available decaffeinated Kaffee
      </p>
    </div>
    <v-divider class="mx-4" color="black"></v-divider>
    <!-- <backtohome></backtohome> -->
    <v-card class="mt-4">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          color="green"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="breakfast"
        :search="search"
        :items-per-page="-1"
      >
        <template v-slot:item.kaffee="{ item }">
          <b>{{ item.kaffee }}</b>
        </template>
        <template v-slot:item.preis="{ item }">
          <b>{{ item.preis }}</b>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// import Backtohome from './Backtohome.vue';
export default {
  // components: { Backtohome },
  name: 'Kaffee',
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Kaffee',
          value: 'kaffee',
        },
        { text: 'Allergene', value: 'allergene' },
        { text: 'Inhalt', value: 'inhalt' },
        { text: 'Preis', value: 'preis' },
      ],
      breakfast: [
        {
          id: 1,
          kaffee: 'Espresso',
          allergene: '',
          inhalt: '',
          preis: '3,30 €',
        },
        {
          id: 2,
          kaffee: 'Kleiner Brauner',
          allergene: '(G)',
          inhalt: '',
          preis: '3,50 €',
        },
        {
          id: 3,
          kaffee: 'Espresso Macchiato',
          allergene: '(G)',
          inhalt: 'mit Milchcreme | with milk froth',
          preis: '3,70 €',
        },
        {
          id: 4,
          kaffee: 'Espresso Moccacchino ',
          allergene: '(G)',
          inhalt: 'mit Schlag | with whipped cream',
          preis: '3,90 €',
        },
        {
          id: 5,
          kaffee: 'Espresso Doppio',
          allergene: '',
          inhalt: '',
          preis: '4,60 €',
        },
        {
          id: 6,
          kaffee: 'Großer Brauner',
          allergene: '(G)',
          inhalt: 'mit heißer Milch',
          preis: '4,80 €',
        },
        {
          id: 7,
          kaffee: 'Espresso Doppio Macchiato',
          allergene: '(G)',
          inhalt: '',
          preis: '4,80 €',
        },
        {
          id: 8,
          kaffee: 'Verlängerter schwarz | americano',
          allergene: '',
          inhalt: '',
          preis: '4,10 €',
        },
        {
          id: 9,
          kaffee: 'Verlängerter Brauner | americano',
          allergene: '(G)',
          inhalt: 'mit Milch | with milk',
          preis: '4,30 €',
        },
        {
          id: 10,
          kaffee: 'Melange | Cappuccino',
          allergene: '(G)',
          inhalt: '',
          preis: '4,80 €',
        },
        {
          id: 11,
          kaffee: 'Melange/Cappuccino mit Schlag',
          allergene: '(G)',
          inhalt: 'mit Schlag | with whipped cream',
          preis: '5,10 €',
        },
        {
          id: 12,
          kaffee: 'Latte Macchiato',
          allergene: '(G)',
          inhalt: '',
          preis: '5,30 €',
        },
        {
          id: 13,
          kaffee: 'Corretto, Espresso mit Grappa',
          allergene: '',
          inhalt: '',
          preis: '7,90 €',
        },
        {
          id: 14,
          kaffee: 'Espresso mit Amaretto',
          allergene: '',
          inhalt: '',
          preis: '8,10 €',
        },
        {
          id: 15,
          kaffee:
            'Affogato, Espresso (nur Eissaison) | espresso with a scoop ice-cream (only in summer)',
          allergene: '(G, H)',
          inhalt:
            'mit einer Kudel Vanilleeis | with a scoop of vanilla ice-cream',
          preis: '5,50 €',
        },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
