<template>
  <div class="container">
    <h1 class="text-center display-3 font-weight-thin mt-8">
      Jugendgetränke | Softdrinks
    </h1>
    <v-card class="mt-6">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          color="green"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="breakfast"
        :search="search"
        :items-per-page="-1"
      >
        <template v-slot:item.getraenk="{ item }">
          <b>{{ item.getraenk }}</b>
        </template>
        <template v-slot:item.preis="{ item }">
          <b>{{ item.preis }}</b>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'Jugendgetränk',
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Getränk',
          value: 'getraenk',
        },
        { text: 'Menge', value: 'menge' },
        { text: 'Preis', value: 'preis' },
      ],
      breakfast: [
        {
          id: 1,
          getraenk: 'Sodawasser',
          menge: '0,25 l',
          preis: '2,30 €',
        },
        {
          id: 2,
          getraenk: 'Sodawasser',
          menge: '0,50 l',
          preis: '4,20 €',
        },
        {
          id: 3,
          getraenk: 'Sodawasser-Zitrone, Himbeer, Holunder',
          menge: '0,25 l',
          preis: '3,10 €',
        },
        {
          id: 4,
          getraenk: 'Sodawasser-Zitrone, Himbeer, Holunder',
          menge: '0,50 l',
          preis: '4,90 €',
        },
        {
          id: 5,
          getraenk: 'Leitungswasser-Zitrone, Himbeer, Holunder',
          menge: '0,50 l',
          preis: '3,70 €',
        },
        {
          id: 6,
          getraenk: 'Glas Wiener Wasser | glass of water',
          menge: '0,25 l',
          preis: '0,50 €',
        },
        {
          id: 7,
          getraenk: 'Portion Zitrone',
          menge: '',
          preis: '0,70 €',
        },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
