<template>
  <div class="container">
    <div class="container mb-2">
      <!-- <v-divider class="mx-4" color="black"></v-divider> -->
      <h1 class="text-center display-3 font-weight-thin mt-2 mb-2">Dessert</h1>
      <!-- <v-divider class="mx-4 mb-10" color="black"></v-divider> -->
    </div>
    <v-card class="mt-4">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          color="green"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="breakfast"
        :search="search"
        :items-per-page="-1"
      >
        <template v-slot:item.picture="{ item }">
          <v-img
            :src="require(`../assets/images/${item.picture}`)"
            max-width="150"
          ></v-img>
        </template>
        <template v-slot:item.speise="{ item }">
          <b>{{ item.speise }}</b>
        </template>
        <template v-slot:item.preis="{ item }">
          <b>{{ item.preis }}</b>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'Nachspeise',
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Nachspeise',
          value: 'speise',
        },
        { text: 'Picture', value: 'picture' },
        { text: 'Allergene', value: 'allergene' },
        { text: 'Anzahl', value: 'anzahl' },
        { text: 'Inhalt', value: 'inhalt' },
        { text: 'Preis', value: 'preis' },
      ],
      breakfast: [
        {
          id: 1,
          speise: 'MARMELADE PALATSCHINKEN | crepe filled with apricot jam',
          picture: 'platzhalter.png',
          allergene: '(A, G, O, C)',
          anzahl: '2 STK. | 2 pieces',
          inhalt: 'mit Marmelade gefüllt | filled with apricot jam',
          preis: '7,50 €',
        },
        {
          id: 2,
          speise: 'NUTELLA PALATSCHINKE | crepe filled with Nutella',
          picture: 'platzhalter.png',
          allergene: '(A, C, G)',
          anzahl: '1 STK. | 1 piece',
          inhalt: 'mit Nutella gefüllt | filled with Nutella',
          preis: '6,80 €',
        },
        {
          id: 3,
          speise: 'KAISERSCHMARRN | roasted pancake dough',
          picture: 'kaiserschmarrn.png',
          allergene: '(A, C, G, O, P)',
          anzahl: '1 Por. | 1 portion',
          inhalt: 'mit Zwetschkenröster | with plum jam on the side',
          preis: '11,90 €',
        },
        {
          id: 4,
          speise: 'APFELSTRUDEL | apple strudel',
          picture: 'apfelstrudel.png',
          allergene: '(A, C, G, F, P)',
          anzahl: '1 Por. | 1 portion',
          inhalt:
            'mit Schlag 1,20 € | mit Vanillesauce 2,50 €',
          preis: '5,90 €',
        },
        {
          id: 5,
          speise: 'TOPFENSTRUDEL | curd cheese strudel',
          picture: 'topfenstrudel.png',
          allergene: '(A, C, G, F, P)',
          anzahl: '1 Por. | 1 portion',
          inhalt:
            'mit Schlag 1,20 € | mit Vanillesauce 2,50 €',
          preis: '5,90 €',
        },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
