<template>
  <div class="home" style="background-color: white">
    <!-- WEIHNACHTSÖFFNUNGSZEITEN -->
          <!-- <div class="container">
        <v-alert color="#00C853" type="info" class="text-center"
          >Sehr geehrte Kunden und Kundinnen wir haben von
          <b>24.12.2024 bis 26.12.2024 geschlossen!</b> <br /><br /><b
            >WIR WÜNSCHEN IHNEN FROHES FEST UND ERHOLSAME FERIEN!</b>
          <br />
            </v-alert>
      </div> -->
    <div class="d-flex flex-wrap container">
      <!-- <v-carousel hide-delimiters cycle height="800px" max-width="100%">
        <v-carousel-item
          v-for="(item, i) in items"
          :key="i"
          :src="item.src"
        ></v-carousel-item>
      </v-carousel> -->

      <v-row class="mt-10">
        <v-col cols="12" sm="6" md="4">
          <v-card
            class="mx-auto"
            max-width="344"
            to="/speisekartenew"
            style="background-color: #212121"
          >
            <!-- <v-img
              src="../assets/images/kalbsschnitzel.png"
              height="220px"
            ></v-img> -->
            <v-carousel
              hide-delimiters
              cycle
              max-height="20%"
              background-size="cover"
            >
              <v-carousel-item
                v-for="(item, i) in speisekarte"
                :key="i"
                :src="item.src"
              ></v-carousel-item>
            </v-carousel>
            <v-card-title
              class="justify-center white--text font-weight-thin"
              style="background-color: #212121"
            >
              Speisekarte
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card class="mx-auto" max-width="344" to="/kontakt">
            <!-- <v-img src="../assets/images/coffeetogo.jpg" height="220px"></v-img> -->
            <v-carousel
              hide-delimiters
              cycle
              max-height="20%"
              background-size="cover"
            >
              <v-carousel-item
                v-for="(item, i) in abholung"
                :key="i"
                :src="item.src"
              ></v-carousel-item>
            </v-carousel>
            <v-card-title
              class="justify-center white--text font-weight-thin"
              style="background-color: #212121"
            >
              Abholung | Reservierung
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card class="mx-auto" max-width="344" to="/kaffeenew">
            <!-- <v-img src="../assets/images/kaffee.jpg" height="220px"></v-img> -->
            <v-carousel
              hide-delimiters
              cycle
              max-height="20%"
              background-size="cover"
            >
              <v-carousel-item
                v-for="(item, i) in kaffee"
                :key="i"
                :src="item.src"
              ></v-carousel-item>
            </v-carousel>
            <v-card-title
              class="justify-center white--text font-weight-thin"
              style="background-color: #212121"
            >
              Kaffee | Coffee
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card
            class="mx-auto"
            max-width="344"
            style="background-color: #212121"
            to="/getraenkekartenew"
          >
            <!-- <v-img src="../assets/images/getraenke.jpg" height="220px"></v-img> -->
            <v-carousel
              hide-delimiters
              cycle
              max-height="20%"
              background-size="cover"
            >
              <v-carousel-item
                v-for="(item, i) in getraenkekarte"
                :key="i"
                :src="item.src"
              ></v-carousel-item>
            </v-carousel>
            <v-card-title
              class="justify-center white--text font-weight-thin"
              style="background-color: #212121"
            >
              Getränkekarte
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card
            class="mx-auto white--text"
            max-width="344"
            to="/oeffnungszeiten"
            style="background-color: #212121"
          >
            <!-- <v-img
              src="../assets/images/cafeamplatzoeffnungszeiten.png"
              height="220px"
            ></v-img> -->
            <v-carousel
              hide-delimiters
              cycle
              max-height="20%"
              background-size="cover"
            >
              <v-carousel-item
                v-for="(item, i) in oeffnungszeiten"
                :key="i"
                :src="item.src"
              ></v-carousel-item>
            </v-carousel>
            <v-card-title class="justify-center white--text font-weight-thin">
              Öffnungszeiten
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card class="mx-auto" max-width="344" to="/ueberuns">
            <v-carousel
              hide-delimiters
              cycle
              max-height="20%"
              background-size="cover"
            >
              <v-carousel-item
                v-for="(item, i) in cafeamplatz"
                :key="i"
                :src="item.src"
              ></v-carousel-item>
            </v-carousel>
            <v-card-title
              class="justify-center white--text font-weight-thin"
              style="background-color: #212121"
            >
              Café am Platz
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',

  components: {},
  data() {
    return {
      items: [
        {
          src: require('../assets/images/mozartschnitte.jpg'),
        },
        {
          src: require('../assets/images/baristaart.jpg'),
        },
        {
          src: require('../assets/images/schokotortevegan.jpg'),
        },
        {
          src: require('../assets/images/kaffee.jpg'),
        },
        {
          src: require('../assets/images/kaffeemaschine.jpg'),
        },
        // {
        //   src: require('../assets/images/coffeetogosmall.jpg'),
        // },
        {
          src: require('../assets/images/coffeetogocreativeart.jpg'),
        },
        //         {
        //   src: require('../assets/images/coffeetogoextrasmall.jpg'),
        // },
        // {
        //   src: require('../assets/images/coffeetogomiddlesize.jpg'),
        // },
      ],
      abholung: [
        {
          src: require('../assets/images/coffeetogoextrasmall.jpg'),
        },
        {
          src: require('../assets/images/coffeetogomiddlesize.jpg'),
        },
        {
          src: require('../assets/images/coffeetogo.jpg'),
        },
      ],
      speisekarte: [
        {
          src: require('../assets/images/kalbsschnitzel.png'),
        },
        {
          src: require('../assets/images/gulasch.png'),
        },
        {
          src: require('../assets/images/kaiserschmarrn.png'),
        },
      ],
      getraenkekarte: [
        {
          src: require('../assets/images/getraenke.jpg'),
        },
        {
          src: require('../assets/images/camparisoda.jpg'),
        },
        {
          src: require('../assets/images/wein.jpg'),
        },
      ],
      kaffee: [
        {
          src: require('../assets/images/kaffee.jpg'),
        },
        {
          src: require('../assets/images/baristaart.jpg'),
        },
      ],
      oeffnungszeiten: [
        {
          src: require('../assets/images/cafeamplatzoeffnungszeiten.png'),
        },
        {
          src: require('../assets/images/cafeamplatzoeffnungszeitentante.png'),
        },
      ],
      cafeamplatz: [
        {
          src: require('../assets/images/kaffeemaschine.jpg'),
        },
        {
          src: require('../assets/images/milchschaum.jpg'),
        },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>
