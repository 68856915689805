<template>
  <div class="container">
    <div class="container mb-2">    
    <!-- <v-divider class="mx-4" color="black"></v-divider> -->
    <h1 class="text-center display-3 font-weight-thin mt-2 mb-2">Wein</h1>
    <!-- <v-divider class="mx-4" color="black"></v-divider> -->
    </div>
    <!-- <backtogetraenke></backtogetraenke> -->
    <v-card class="mt-2">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          color="green"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="breakfast"
        :search="search"
        :items-per-page="-1"
      >
        <template v-slot:item.wein="{ item }">
          <b>{{ item.wein }}</b>
        </template>
        <template v-slot:item.preis="{ item }">
          <b>{{ item.preis }}</b>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// import Backtogetraenke from './Backtogetraenke.vue';
export default {
  // components: { Backtogetraenke },
  name: 'Wein',
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Wein',
          value: 'wein',
        },
        { text: 'Menge', value: 'menge' },
        { text: 'Preis', value: 'preis' },
      ],
      breakfast: [
        {
          id: 1,
          wein: 'Grüner Veltliner',
          menge: '1/8 l',
          preis: '3,10 €',
        },
        {
          id: 2,
          wein: 'Zweigel',
          menge: '1/8 l',
          preis: '3,30 €',
        },
        {
          id: 3,
          wein: 'Weiss gespritzt | dry white wine with sparkling water',
          menge: '1/4 l',
          preis: '4,10 €',
        },
        {
          id: 4,
          wein: 'Rot gespritzt | dry red wine with sparkling water',
          menge: '1/4 l',
          preis: '4,30 €',
        },
        {
          id: 5,
          wein: 'Aperol Spritz mit Wein',
          menge: '1/4 l',
          preis: '5,80 €',
        },
        {
          id: 6,
          wein: 'Aperol Spritz mit Prosecco',
          menge: '1/4 l',
          preis: '6,90 €',
        },
        {
          id: 7,
          wein: 'Prosecco',
          menge: '0,1 l',
          preis: '5,10 €',
        },
        {
          id: 8,
          wein: 'Prosecco-Orange',
          menge: '0,1 l',
          preis: '5,90 €',
        },
        {
          id: 9,
          wein: 'Hugo mit Prosecco',
          menge: '1/4 l',
          preis: '6,90 €',
        },
        {
          id: 10,
          wein: 'Kaiserspritzer mit Wein',
          menge: '1/4 l',
          preis: '4,60 €',
        },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
