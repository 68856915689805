<template>
  <div class="container">
    <h1 class="text-center display-3 font-weight-thin mt-8">Breakfast</h1>
    <div class="container text-center">
      <v-divider class="mx-4 mt-4 mb-4" color="black"></v-divider>
      <p class="font-italic">
        <b>Täglich von 8:00 - 11:00 Uhr</b> <br />daily from 8 am - 11 am
      </p>
      <p class="font-italic">
        <b>0,90 € Aufpreis</b> <br />Café Latte, Espresso doppio & Kännchen
        Kaffee
      </p>
      <v-divider class="mx-4" color="black"></v-divider>
    </div>
    <!-- <backtofruehstueckauswahl></backtofruehstueckauswahl> -->
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          color="green"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="breakfast"
        :search="search"
        :items-per-page="-1"
      >
        <template v-slot:item.picture="{ item }">
          <v-img
            :src="require(`../assets/images/${item.picture}`)"
            max-width="150"
          ></v-img>
        </template>
        <template v-slot:item.speise="{ item }">
          <b>{{ item.speise }}</b>
        </template>
        <template v-slot:item.preis="{ item }">
          <b>{{ item.preis }}</b>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// import Backtofruehstueckauswahl from './Backtofruehstueckauswahl.vue';
export default {
  // components: { Backtofruehstueckauswahl },
  name: 'Fruehstueck',
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Speise',
          value: 'speise',
        },
        { text: 'Picture', value: 'picture' },
        { text: 'Allergene', value: 'allergene' },
        { text: 'Inhalt', value: 'inhalt' },
        { text: 'Preis', value: 'preis' },
      ],
      breakfast: [
        {
          id: 1,
          speise: 'FÜR EILIGE',
          picture: 'platzhalter.png',
          allergene: '(A, G)',
          inhalt:
            'Semmel und Butter mit Kaffee oder heiße Schokolade | roll and butter with coffee or hot chocolate',
          preis: '5,90 €',
        },
        {
          id: 2,
          speise: 'CROISSANT FRÜHSTÜCK',
          picture: 'platzhalter.png',
          allergene: '(A, C, G)',
          inhalt:
            'Frühstücksgetränk, 1/8 O-saft und ein Croissant | hot drink, a glass of orange juice and a croissant',
          preis: '8,20 €',
        },
        {
          id: 3,
          speise: 'LEICHTES FRÜHSTÜCK',
          picture: 'leichtesfruehstueck.png',
          allergene: '(A, C, G, N)',
          inhalt:
            'Frühstücksgetränk, 1/8 O-saft, ein weiches Ei, Vollkornbrot mit Frischkäse und Schnittlauch',
          preis: '11,60 €',
        },
        {
          id: 4,
          speise: 'POWER FRÜHSTÜCK',
          picture: 'powerfruehstueck.png',
          allergene: '(A, C, G, N)',
          inhalt:
            'Frühstücksgetränk, 1/8 O-saft, 2 Eier im Glas mit gegrillten Tomaten & Kresse, dazu Butter und ein Kornspitz | hot drink, orange juice, 2 peeled eggs in a glass with warm tomatoes & crees, butter and grain bun',
          preis: '12,90 €',
        },
        {
          id: 5,
          speise: 'Café am Platz FRÜHSTÜCK',
          picture: 'cafeamplatzfruehstueck.png',
          allergene: '(A, C, G, N)',
          inhalt:
            'Frühstücksgetränk, 1/8 O-saft, Eierspeise (2 Eier) mit Schnittlauch, Butter, Schinken, Käse, Marmelade, Semmel, Kornspitz und ein Stück Croissant | hot drink, a glass of orange juice, scrabled eggs with chives, butter, cheese, ham, jam, roll, grain bun and croissant',
          preis: '15,90 €',
        },
        {
          id: 6,
          speise: 'VEGANES FRÜHSTÜCK',
          picture: 'veganesfruehstueck.png',
          allergene: '(A, N, O, P)',
          inhalt:
            'Frühstücksgetränk, 1/8 O-saft, Fruchtsalat, Margarine, Hummus, Tomaten, Gurken, Paprika, Semmel & Kornspitz | hot drink, a glass of orange juice, fruit salad, honey, margarine, tomatoes, cucumber, bell pepper, hummus, roll & grain bun',
          preis: '16,10 €',
        },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
