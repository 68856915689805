<template>
  <div class="container">
    <div class="container mb-2">
      <!-- <v-divider class="mx-4" color="black"></v-divider> -->
      <h1 class="text-center display-3 font-weight-thin mt-2 mb-2">
        Spirituosen
      </h1>
      <!-- <v-divider class="mx-4" color="black"></v-divider> -->
    </div>
    <!-- <backtogetraenke></backtogetraenke> -->
    <v-card class="mt-2">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          color="green"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="breakfast"
        :search="search"
        :items-per-page="-1"
      >
        <template v-slot:item.schnaps="{ item }">
          <b>{{ item.schnaps }}</b>
        </template>
        <template v-slot:item.preis="{ item }">
          <b>{{ item.preis }}</b>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// import Backtogetraenke from './Backtogetraenke.vue';
export default {
  // components: { Backtogetraenke },
  name: 'Spirituosen',
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Spirituose',
          value: 'schnaps',
        },
        { text: 'Menge', value: 'menge' },
        { text: 'Preis', value: 'preis' },
      ],
      breakfast: [
        {
          id: 1,
          schnaps: 'Marille Schnaps| apricot Liquor',
          menge: '2 cl',
          preis: '5,10 €',
        },
        {
          id: 2,
          schnaps: 'Fernet Branca',
          menge: '2 cl',
          preis: '5,10 €',
        },
        {
          id: 3,
          schnaps: "Bailey's irish cream",
          menge: '4 cl',
          preis: '7,90 €',
        },
        {
          id: 4,
          schnaps: 'Vodka Stolichnaya',
          menge: '2 cl',
          preis: '5,10 €',
        },
        {
          id: 5,
          schnaps: 'Gin Bombay Saphire',
          menge: '2 cl',
          preis: '5,50 €',
        },
        {
          id: 6,
          schnaps: 'Averna',
          menge: '2 cl',
          preis: '5,10 €',
        },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
