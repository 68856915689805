<template>
  <div class="container" style="background-color: ">
    <div class="container mb-2">
      <!-- WEIHNACHTSOEFFNUNGSZEITEN -->
      <!-- <h3 class="text-center display-3 font-weight-thin mt-2 mb-2">
        Weihnachts= <br> öffnungszeiten
      </h3>
      <div class="d-flex flex-wrap mt-10 container">
        <v-row>
          <v-col cols="24" sm="12" md="6">
            <v-card
              class="mx-auto white--text"
              max-width="450"
              height="220"
              to="/oeffnungszeiten"
              style="background-color: #212121"
            >
              <v-card-subtitle class="text-center"
                ><v-alert type="error" color="red"
                  >GESCHLOSSEN</v-alert
                ></v-card-subtitle
              >
              <v-card-subtitle
                class="text-center text-h6 white--text overline font-weight-light"
              >
                von 24.12 bis 26.12.2024<br />GESCHLOSSEN
              </v-card-subtitle>
            </v-card>
          </v-col>
          <v-col cols="24" sm="12" md="6">
            <v-card
              class="mx-auto white--text"
              max-width="450"
              height="220"
              to="/oeffnungszeiten"
              style="background-color: #212121"
            >
              <v-card-subtitle class="text-center"
                ><v-alert type="info" color="red"
                  >CHRISTMAS OPENING HOURS</v-alert
                ></v-card-subtitle
              >
              <v-card-subtitle
                class="text-center text-h6 white--text overline font-weight-light"
              >
                closed from December 24th to December 26th <br />
                WE WISH YOU A MERRY CHRISTMAS
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </div> -->
      <!-- WEIHNACHTSOEFFNUNGSZEITEN ENDE -->
      <!-- <br /> -->
      <!-- <br /><br /> -->
      <!-- <v-divider class="mx-4 mb-4 mt-2" color="black"></v-divider> -->
      <h1 class="text-center display-3 font-weight-thin mt-2 mb-2">
        Öffnungszeiten
      </h1>
      <!-- <v-divider class="mx-4 mt-4" color="black"></v-divider> -->
    </div>
    <div class="d-flex flex-wrap container">
      <backtohome class="mb-2"></backtohome>
      <v-row>
        <v-col cols="24" sm="12" md="6">
          <v-card
            class="mx-auto white--text"
            max-width="450"
            height="220"
            to="/oeffnungszeiten"
            style="background-color: #212121"
          >
            <!-- <v-img src="../assets/images/opentime.jpg" height="200px"></v-img> -->
            <v-card-subtitle class="text-center"
              ><v-alert type="success" color="green"
                >GEÖFFNET</v-alert
              ></v-card-subtitle
            >
            <v-card-subtitle class="text-center text-h6 white--text">
              TÄGLICH <br />
              8:00 - 19:00 Uhr
            </v-card-subtitle>
          </v-card>
        </v-col>
        <v-col cols="24" sm="12" md="6">
          <v-card
            class="mx-auto white--text"
            max-width="450"
            height="220"
            to="/oeffnungszeiten"
            style="background-color: #212121"
          >
            <!-- <v-img src="../assets/images/opentime.jpg" height="200px"></v-img> -->
            <v-card-subtitle class="text-center"
              ><v-alert type="success" color="green"
                >OPENING HOURS</v-alert
              ></v-card-subtitle
            >
            <v-card-subtitle class="text-center text-h6 white--text">
              DAILY<br />
              8:00 am - 19:00 pm
            </v-card-subtitle>
          </v-card>
        </v-col>
        <!-- <v-col cols="24" sm="12" md="6">
          <v-card
            class="mx-auto white--text"
            max-width="450"
            height="220"
            to="/oeffnungszeiten"
            style="background-color:#212121;"
          >
            <v-img src="../assets/images/opentime.jpg" height="200px"></v-img>
            <v-card-subtitle class="text-center"
              ><v-alert type="error" color="red"
                >GESCHLOSSEN</v-alert
              ></v-card-subtitle
            >
            <v-card-subtitle class="text-center text-h6 white--text">
              Samstags geschlossen!
            </v-card-subtitle>
          </v-card>
        </v-col> -->
      </v-row>
    </div>
  </div>
</template>

<script>
import Backtohome from './Backtohome.vue';
export default {
  components: {
    Backtohome,
  },
  name: 'Oeffnungszeiten',
  data() {
    return {};
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
