<template>
  <div class="container">
    <div class="container mb-2">    
    <!-- <v-divider class="mx-4" color="black"></v-divider> -->
    <h1 class="text-center display-3 font-weight-thin mt-2 mb-2">Bier | beer</h1>
    <!-- <v-divider class="mx-4" color="black"></v-divider> -->
    </div>
    <!-- <backtogetraenke></backtogetraenke> -->
    <v-card class="mt-2">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          color="green"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="breakfast"
        :search="search"
        :items-per-page="-1"
      >
        <template v-slot:item.bier="{ item }">
          <b>{{ item.bier }}</b>
        </template>
        <template v-slot:item.preis="{ item }">
          <b>{{ item.preis }}</b>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// import Backtogetraenke from './Backtogetraenke.vue';
export default {
  // components: { Backtogetraenke },
  name: 'Bier',
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Bier',
          value: 'bier',
        },
        { text: 'Menge', value: 'menge' },
        { text: 'Allergene', value: 'allergene' },
        { text: 'Preis', value: 'preis' },
      ],
      breakfast: [
        {
          id: 1,
          bier: 'Villacher vom Fass draft',
          menge: '0,30 l',
          allergene: '(A)',
          preis: '4,60 €',
        },
        {
          id: 2,
          bier: 'Villacher vom Fass draft',
          menge: '0,50 l',
          allergene: '(A)',
          preis: '5,80 €',
        },
        {
          id: 3,
          bier: 'Alkoholfreies Bier | no alcoholic bottled beer',
          menge: 'Fl. 0,50 l',
          allergene: '(A)',
          preis: '5,70 €',
        },
        {
          id: 4,
          bier: 'Radler | beer mixed with lemonade',
          menge: 'Fl. 0,50 l',
          preis: '5,50 €',
        },
        {
          id: 5,
          bier: 'Weißbier',
          menge: 'Fl. 0,50 l',
          preis: '5,90 €',
        },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
